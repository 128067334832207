<template>
    <div class="video-id-presenter">
        {{ video.publish_date ? simpleDate : 'N/A' }}
    </div>
</template>

<script>
    import TimeHelper from '@/time-helper';

    export default {
        name: 'UploadDatePresenter',
        props: {
            video: {
                required: true,
                type: Object
            }
        },
        computed: {
            simpleDate() {
                return TimeHelper.formatYmdHi(TimeHelper.fromUnixToClientsTz(this.video.publish_date));
            },
        }
    };
</script>

<style scoped>

</style>