<template>
    <div class="uploaded-by-presenter">
        {{ video.metadata.uploadedBy }}
    </div>
</template>

<script>
    export default {
        name: 'UploadedByPresenter',
        props: {
            video: {
                required: true,
                type: Object
            }
        },
    };
</script>

<style scoped>

</style>