<template>
    <v-simple-table
        dense
        class="property-sheet player-template-editor"
    >
        <template v-slot:default>
            <tbody>
                <tr>
                    <td>Player template</td>
                    <td>
                        <v-row no-gutters>
                            <v-col cols="8">
                                <v-select
                                    v-if="editing"
                                    v-model="selectedTemplate"
                                    :items="playerTemplates"
                                    item-text="name"
                                    item-value="id"
                                    dense
                                    flat
                                />
                                <div v-else>
                                    {{ selectedTemplateName }}
                                    <v-btn x-small
                                           text
                                           color="primary"
                                           target="_blank"
                                           :href="resolveTemplateManagementLink"
                                    >
                                        Manage selected template
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col
                                cols="4"
                                class="text-right"
                            >
                                <v-btn
                                    v-if="editing"
                                    class="mr-2"
                                    x-small
                                    icon
                                    color="primary"
                                    @click="editDone"
                                >
                                    <v-icon>done</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="editing"
                                    x-small
                                    icon
                                    color="error"
                                    @click="editCancelled"
                                >
                                    <v-icon>close</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="!editing"
                                    class="mr-2"
                                    x-small
                                    icon
                                    color="primary"
                                    @click="onEditStart"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
    import {$http, playerTemplateApiUrl} from '../../../../services/http';
    import {toastError} from '../../../../services/responseErrors';
    import updateMeta from '../../../../services/metadata';

    export default {
        name: 'PlayerTemplateEditor',
        props: ['video'],
        components: {},
        computed: {
            selectedTemplateName() {
                const selected = this.playerTemplates.filter(item => item.id === this.selectedTemplate);
                const def = this.playerTemplates.filter(item => item.is_default === 1);
                return selected.length ? selected[0].name : (def.length ? def[0].name : '');
            },
            resolveTemplateManagementLink() {
                return this.$router.resolve({
                    name: 'editLibraryPlayerTemplate',
                    params: {
                        libraryId: this.video.library,
                        templateId: this.selectedTemplate,
                    }
                }).href;
            }
        },
        data() {
            return {
                editing: false,
                selectedTemplate: this.video.playerTemplateId,
                playerTemplates: [],
            };
        },
        methods: {
            onEditStart() {
                this.editing = true;
            },
            editDone() {
                updateMeta(this.video.id, {
                    playerTemplateId: this.selectedTemplate && this.selectedTemplate > 0 ? this.selectedTemplate : null
                }).then(() => {
                    this.video.playerTemplateId = this.selectedTemplate;
                    this.editing = false;
                });
            },
            editCancelled() {
                this.editing = false;
            },
            getTemplates() {
                $http.get(playerTemplateApiUrl('/libraries/' + this.video.library + '/player-templates-for-video'))
                    .then(response => {
                        this.playerTemplates = response.data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
                        if (!this.selectedTemplate) {
                            const def = this.playerTemplates.filter(item => item.is_default === 1);
                            this.selectedTemplate = def.length ? def[0].id : null;
                        }
                    })
                    .catch(() => {
                        toastError('Failed to load player templates');
                    });
            }
        },
        mounted() {
            this.getTemplates();
        }

    };
</script>

<style lang="scss">
    .player-template-editor {
        .v-select {
            margin-top: -5px !important;

            .v-select__selections {
                .v-select__selection {
                    font-size: 0.8rem;
                    line-height: 16px;
                }
            }
        }

        .mx-input {
            height: 25px !important;
            font-size: 12px !important;
        }

        .v-text-field__details {
            display: none;
        }
    }
</style>
