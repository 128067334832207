<template>
    <div class="video-id-presenter">
        {{ video ? video.id : "" }}
        <v-tooltip v-if="video"
                   top
        >
            <template v-slot:activator="{on}">
                <v-btn icon
                       small
                       color="primary"
                       v-on="on"
                       @click="copyVideoIdToClipboard"
                >
                    <v-icon small>
                        content_copy
                    </v-icon>
                </v-btn>
            </template>
            <span>Copy video ID</span>
        </v-tooltip>
    </div>
</template>

<script>
    import {toastSuccess} from '@/services/responseErrors';

    export default {
        name: 'VideoIdPresenter',
        props: {
            video: {
                required: true,
                type: Object
            }
        },
        methods: {
            async copyVideoIdToClipboard() {
                await navigator.clipboard.writeText(this.video.id);
                toastSuccess('Video Id copied to clipboard');
            }
        }
    };
</script>

<style scoped>

</style>