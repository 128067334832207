
const auth = {
    namespaced: true,
    state: {
        authenticated: false,
        user: {
            id: null,
            name: '',
            picture: '',
            role: null,
            company: null,
        },
    },
    getters: {
        user(state) {
          return state.user;
        },
        role(state) {
            return state.user.role;
        },
        canEditRoleOf: state => editedUser => {
            if (parseInt(editedUser.id) === parseInt(state.user.id)) {
                return false;
            }

            if ((editedUser.role === 'super-admin' || editedUser.role === 'system-admin') && state.user.role === 'system-admin') {
                return false;
            }

            if (editedUser.role === 'company-admin' && state.user.role === 'company-admin') {
                return true;
            }

            if (state.user.role === 'company-admin') {
                return true;
            }

            if (state.user.role === 'super-admin') {
                return true;
            }

            if (editedUser.role !== state.user.role) {
                return true;
            }

            return false;
        },
        canEditUserCompany: state => state.user.role && (state.user.role === 'super-admin' || state.user.role === 'system-admin'),
        sameAs: state => user => state.user.id === user.id,
        isSystemAdmin: state => state.user.role === 'system-admin',
        isSuperAdmin: state => state.user.role === 'super-admin',
        canUploadEverywhere: state => state.user.uploadEverywhere || false,
        ftpFolderAccess: state => state.user.permissions['ftp-folder-access'],
        isLoggedIn: state => !!state.user.id,
    },
    mutations: {
        login(state, payload) {
            state.user.id = payload.user_id;
            state.user.name = payload.name || payload.email;
            state.user.picture = payload.picture || '';
            state.user.company = payload.company;
            state.user.role = payload.PermissionBag.role;
            state.user.permissions = payload.PermissionBag.permissions;
            state.user.uploadEverywhere = payload.PermissionBag.uploadEverywhere;
            state.authenticated = true;
            localStorage.setItem('platform_logged_in', '1');
        },
        logout(state) {
            state.authenticated = false;
            state.user = {
                id: null,
                name: '',
                picture: '',
                role: null,
                company: null,
            };
        }
    }
};

export default auth;

