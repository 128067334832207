<template>
    <div class="video-id-presenter">
        {{ visibilityTypeText }}
    </div>
</template>

<script>
    import VisibilityHelper from '@/visibility-helper';

    export default {
        name: 'VisibilityPresenter',
        props: {
            video: {
                required: true,
                type: Object
            }
        },
        computed: {
            visibilityTypeText() {
                return VisibilityHelper.getOption(this.video.visibility.type);
            }
        }
    };
</script>

<style scoped>

</style>